// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wlOb3MtFS", "inyA5R4ZH", "OPuDpS4mY", "FtEInQgl9", "h33CcvXwt", "Fafo_unHL", "crDNdnWmk", "fyC4igFUR", "xjv_7hx63", "NuE2gxZUD", "uwXMq7pSk", "yukO694yq", "mCGJgFAUk", "YPv3wycTT"];

const serializationHash = "framer-2vxUH"

const variantClassNames = {crDNdnWmk: "framer-v-lx10re", Fafo_unHL: "framer-v-17aycd2", FtEInQgl9: "framer-v-sb8lk9", fyC4igFUR: "framer-v-l1ntka", h33CcvXwt: "framer-v-1w230o1", inyA5R4ZH: "framer-v-mk12by", mCGJgFAUk: "framer-v-wkv2su", NuE2gxZUD: "framer-v-1i3jc9z", OPuDpS4mY: "framer-v-x9b7zx", uwXMq7pSk: "framer-v-rv0jrh", wlOb3MtFS: "framer-v-ts1jeu", xjv_7hx63: "framer-v-sjzc28", YPv3wycTT: "framer-v-1syq34e", yukO694yq: "framer-v-1242pkq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"2XL - 80px": "FtEInQgl9", "2XS - 16px": "NuE2gxZUD", "3XL - 96px": "OPuDpS4mY", "3XS - 12px": "uwXMq7pSk", "4XL - 120px": "inyA5R4ZH", "4XS - 8px": "yukO694yq", "5XL - 160px": "wlOb3MtFS", "5XS - 4px": "mCGJgFAUk", "6XS - 2px": "YPv3wycTT", "L - 48px": "Fafo_unHL", "M - 40px": "crDNdnWmk", "S - 32px": "fyC4igFUR", "XL - 64px": "h33CcvXwt", "XS - 24px": "xjv_7hx63"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "wlOb3MtFS"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wlOb3MtFS", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "FtEInQgl9") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ts1jeu", className, classNames)} data-framer-name={"5XL - 160px"} layoutDependency={layoutDependency} layoutId={"wlOb3MtFS"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({crDNdnWmk: {"data-framer-name": "M - 40px"}, Fafo_unHL: {"data-framer-name": "L - 48px"}, FtEInQgl9: {"data-framer-name": "2XL - 80px"}, fyC4igFUR: {"data-framer-name": "S - 32px"}, h33CcvXwt: {"data-framer-name": "XL - 64px"}, inyA5R4ZH: {"data-framer-name": "4XL - 120px"}, mCGJgFAUk: {"data-framer-name": "5XS - 4px"}, NuE2gxZUD: {"data-framer-name": "2XS - 16px"}, OPuDpS4mY: {"data-framer-name": "3XL - 96px"}, uwXMq7pSk: {"data-framer-name": "3XS - 12px"}, xjv_7hx63: {"data-framer-name": "XS - 24px"}, YPv3wycTT: {"data-framer-name": "6XS - 2px"}, yukO694yq: {"data-framer-name": "4XS - 8px"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-alignment": "center"}}><motion.br className={"trailing-break"}/></motion.p></React.Fragment>} className={"framer-ut0u9e"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"brnA_ROSe"} style={{"--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2vxUH.framer-8ifol9, .framer-2vxUH .framer-8ifol9 { display: block; }", ".framer-2vxUH.framer-ts1jeu { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 160px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 160px; }", ".framer-2vxUH .framer-ut0u9e { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2vxUH.framer-ts1jeu { gap: 0px; } .framer-2vxUH.framer-ts1jeu > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-2vxUH.framer-ts1jeu > :first-child { margin-top: 0px; } .framer-2vxUH.framer-ts1jeu > :last-child { margin-bottom: 0px; } }", ".framer-2vxUH.framer-v-mk12by.framer-ts1jeu { height: 120px; width: 120px; }", ".framer-2vxUH.framer-v-x9b7zx.framer-ts1jeu { height: 96px; width: 96px; }", ".framer-2vxUH.framer-v-sb8lk9.framer-ts1jeu { height: 80px; width: 80px; }", ".framer-2vxUH.framer-v-1w230o1.framer-ts1jeu { height: 64px; width: 64px; }", ".framer-2vxUH.framer-v-17aycd2.framer-ts1jeu { height: 48px; width: 48px; }", ".framer-2vxUH.framer-v-lx10re.framer-ts1jeu { height: 40px; width: 40px; }", ".framer-2vxUH.framer-v-l1ntka.framer-ts1jeu { height: 32px; width: 32px; }", ".framer-2vxUH.framer-v-sjzc28.framer-ts1jeu { height: 24px; width: 24px; }", ".framer-2vxUH.framer-v-1i3jc9z.framer-ts1jeu { height: 16px; width: 16px; }", ".framer-2vxUH.framer-v-rv0jrh.framer-ts1jeu { height: 12px; width: 12px; }", ".framer-2vxUH.framer-v-1242pkq.framer-ts1jeu { height: 8px; width: 8px; }", ".framer-2vxUH.framer-v-wkv2su.framer-ts1jeu { height: 4px; width: 4px; }", ".framer-2vxUH.framer-v-1syq34e.framer-ts1jeu { height: 2px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"inyA5R4ZH":{"layout":["fixed","fixed"]},"OPuDpS4mY":{"layout":["fixed","fixed"]},"FtEInQgl9":{"layout":["fixed","fixed"]},"h33CcvXwt":{"layout":["fixed","fixed"]},"Fafo_unHL":{"layout":["fixed","fixed"]},"crDNdnWmk":{"layout":["fixed","fixed"]},"fyC4igFUR":{"layout":["fixed","fixed"]},"xjv_7hx63":{"layout":["fixed","fixed"]},"NuE2gxZUD":{"layout":["fixed","fixed"]},"uwXMq7pSk":{"layout":["fixed","fixed"]},"yukO694yq":{"layout":["fixed","fixed"]},"mCGJgFAUk":{"layout":["fixed","fixed"]},"YPv3wycTT":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbY_PU2TCC: React.ComponentType<Props> = withCSS(Component, css, "framer-2vxUH") as typeof Component;
export default FramerbY_PU2TCC;

FramerbY_PU2TCC.displayName = "Spacing Block";

FramerbY_PU2TCC.defaultProps = {height: 160, width: 160};

addPropertyControls(FramerbY_PU2TCC, {variant: {options: ["wlOb3MtFS", "inyA5R4ZH", "OPuDpS4mY", "FtEInQgl9", "h33CcvXwt", "Fafo_unHL", "crDNdnWmk", "fyC4igFUR", "xjv_7hx63", "NuE2gxZUD", "uwXMq7pSk", "yukO694yq", "mCGJgFAUk", "YPv3wycTT"], optionTitles: ["5XL - 160px", "4XL - 120px", "3XL - 96px", "2XL - 80px", "XL - 64px", "L - 48px", "M - 40px", "S - 32px", "XS - 24px", "2XS - 16px", "3XS - 12px", "4XS - 8px", "5XS - 4px", "6XS - 2px"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerbY_PU2TCC, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})